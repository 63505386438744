import React from 'react'
import { useSelector } from 'react-redux'
import UpcomingBreaks from './UpcomingBreaks'
import BreaksOnSale from './BreaksOnSale'
import PersonalBreakCard from './PersonalBreakCard'
import AuctionBreakCard from './AuctionBreakCard'
import { selectAnnouncement, selectPersonalBreaksAvailable, selectLoading, selectAuctionBreaksAvailable } from './breaksSlice'
import moment from 'moment'
import { MoonLoader } from 'react-spinners'
import Annoucement from '../Announcement/Announcement'

const Breaks = () => {
    const announcement = useSelector(selectAnnouncement)
    const personalBreaksAvailable = useSelector(selectPersonalBreaksAvailable)
    const auctionBreaksAvailable = useSelector(selectAuctionBreaksAvailable)
    const loading = useSelector(selectLoading)

    let upcoming = false

    if (announcement) {
        const now = moment(new Date())
        const nextBreakOnSale = moment(announcement.next_break_on_sale_date)

        upcoming = nextBreakOnSale.isAfter(now)
    }

    if (loading) {
        return (
            <div style={{ textAlign: 'center' }}>
                <MoonLoader css='margin: auto; margin-top: 2em' />
                <h4 style={{ marginTop: '1em' }}>Fetching data...</h4>
            </div>
        )
    }

    return (
        <div className='container-fluid mb-5'>
            {announcement && <Annoucement />}
            {upcoming ? <UpcomingBreaks /> : <BreaksOnSale />}
            {personalBreaksAvailable && <PersonalBreakCard />}
            {auctionBreaksAvailable && <AuctionBreakCard />}
        </div>
    )
}

export default Breaks
