import { createSlice } from "@reduxjs/toolkit";
import { breaksUrl } from "../../lib/Endpoints";
import Request from '../../lib/Request'

export const breaksSlice = createSlice({
    name: 'breaks',
    initialState: {
        sports: [],
        announcement: null,
        personalBreaksAvailable: false,
        auctionBreaksAvailable: false,
        loading: false
    },
    reducers: {
        setSports: (state, action) => {
            state.sports = action.payload.sports
            state.announcement = action.payload.announcement
            state.personalBreaksAvailable = action.payload.personal_breaks
            state.auctionBreaksAvailable = action.payload.auction_breaks
            state.loading = false
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }
    }
})

export const {
    setSports,
    setLoading
} = breaksSlice.actions

export const selectSports = state => state.breaks.sports
export const selectAnnouncement = state => state.breaks.announcement
export const selectPersonalBreaksAvailable = state => state.breaks.personalBreaksAvailable
export const selectAuctionBreaksAvailable = state => state.breaks.auctionBreaksAvailable
export const selectLoading = state => state.breaks.loading

export function loadBreaks() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))
        const response = await Request.get(breaksUrl)
        dispatch(setSports(response.data))
    }
}

export default breaksSlice.reducer
