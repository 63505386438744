import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Login from './features/User/Login';
import { selectToken, selectUser, loadUserByToken } from './features/User/userSlice';
import { selectVersion, loadSettings, selectRequiresRefresh } from './features/AppSettings/appSettingsSlice'
import AppRefreshPrompt from './features/AppSettings/AppRefreshPrompt'
import AppRouter from './Router';
import Nav from './features/Nav/Nav';
import { BrowserRouter as Router } from 'react-router-dom'

const App = () => {
    const token = useSelector(selectToken)
    const user = useSelector(selectUser)
    const version = useSelector(selectVersion)
    const requiresRefresh = useSelector(selectRequiresRefresh)

    const dispatch = useDispatch()

    useEffect(() => {
        console.log('App effect...')
        if (!token) {
            return
        }
        // whenever we have a token available but no user loaded, we dispatch the
        // load user by token action... the user slice will load the token right away
        // so behind the scenes we can load the actual user by their header token
        if (!user) {
            dispatch(loadUserByToken())
        }

        if (!version) {
            console.log('get version...')
            dispatch(loadSettings())

            window.setInterval(() => dispatch(loadSettings()), 300000);
        }
    // eslint-disable-next-line
    }, [token, version])

    return (
        <Router>
            <Nav />
            { requiresRefresh ? <AppRefreshPrompt /> : null }
            { token ? <AppRouter /> : <Login /> }
        </Router>
    )
}

export default App;
