import query from 'query-string'
import { setToken, getToken } from './Storage'

/**
 * Get the token param out of the url (?token=)
 */
const parseUrlToken = () => {
    return query.parse(window.location.search).token
}

/**
 * Check for auth tokens both in request URL (first) and then local storage
 *
 * The reason we don't simply always get it from the URL is
 * so we can develop in the web and not have to pass valid tokens
 * in the URL manually... this way if we get a token, we put
 * it in local storage no matter what, so if we launch the app
 * later we'll still have a valid token ready to use in storage.
 */
export function evalToken() {
    const urlToken = parseUrlToken()

    if (urlToken) {
        console.log('Using url token...')
        setToken(urlToken)
        return urlToken
    } else {
        console.log('Checking storage token...')
        const storageToken = getToken()
        return storageToken
    }
}
