import React from 'react'
import { useDispatch } from 'react-redux'
import Toggle from 'react-toggle'
import { updateLeagueInterests } from './alertsSlice'

const sportName = sport => {
    switch(sport) {
        case 'Baseball':
            return 'MLB'
        case 'Football':
            return 'NFL'
        case 'Basketball':
            return 'NBA'
        default:
            return sport
    }
}

const SportAlertToggle = ({ sport }) => {
    const dispatch = useDispatch()

    return (
        <div className='sport-toggle' style={{ marginBottom: '20px' }}>

            <Toggle
                checked={sport.enabled}
                onChange={() => dispatch(updateLeagueInterests(sport.sport.id, !sport.enabled))} />

            &emsp;

            {sportName(sport.sport.name)}

        </div>
    )
}

export default SportAlertToggle