import React from 'react'
import { useSelector } from 'react-redux'
import { selectAnnouncement } from './breaksSlice'
import moment from 'moment'

const UpcomingSportBreak = ({ sport, image }) => {
    const announcement = useSelector(selectAnnouncement)

    return (
        <div className="row">
            <div className="col">
                <div className="card mt-3 text-white bg-dark">
                    <div className="row no-gutters">
                        <div className="col-md-3">
                            <img src={image} className="card-img" alt="break" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <b><p className="card-title">SPOTS ON SALE</p></b>
                                <b><p className="card-title">{moment(announcement.next_break_on_sale_date).format('M/D/Y @ h:mm A')}</p></b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpcomingSportBreak
