export const root = process.env.REACT_APP_API_URL

export const apiRoot = `${root}/api`

export const cableUrl = `${root}/cable`

export const userDraftPreferencesUrl = `${root}/user_draft_preferences`

export const loginUrl = `${apiRoot}/users/login`

export const breaksUrl = `${apiRoot}/breaks`

export const loadUserByTokenUrl = `${apiRoot}/users/load_by_token`

export const appSettingsUrl = `${apiRoot}/app_settings`

export const leagueInterestUrl = `${apiRoot}/users/league_interest`

export const updateLeagueInterestUrl = `${apiRoot}/users/update_league_interest`

export const chatMessagesUrl = `${root}/chat_messages.json`

export const actionChatMessageUrl = `${root}/chat_messages`

export const upcomingSportImage = sport => {
    return `${root}/images/upcoming-${sport}.png`
}

export const breakImageUrl = breakId => {
    return `${apiRoot}/breaks/${breakId}/image`
}

export const updateUserUrl = userId => {
    return `${apiRoot}/users/${userId}`
}
