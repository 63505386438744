import axios from 'axios'
import { getToken, removeToken } from './Storage'

// Create axios instance
const instance = axios.create()

// Interceptors
instance.interceptors.request.use(
    (request) => handleRequest(request)
)

instance.interceptors.response.use(
    (response) => handleSuccess(response),
    (error) => handleError(error),
)

// Interceptor handlers
const handleRequest = (request) => {
    request.headers.common['Authorization'] = getToken()

    return request
}

const handleSuccess = (response) => {
    return response
}

const handleError = (error) => {
    alert(error.response.status)
    // whenever we have an error we try to alert the error response data message,
    // but if that breaks just alert the generic error message
    try {
        if (error.response.status === 401) {
            removeToken()
            window.location.assign('/')
        } else {
            alert(error.response.data.message)
        }
    } catch (e) {
        alert(error)
    }

    return Promise.reject({ ...error })
}

export default instance
