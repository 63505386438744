import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { userDraftPreferencesUrl } from '../../lib/Endpoints'

import {
    selectUser,
    updateUserAttribute,
    attemptUserUpdate,
    selectLoading
} from './userSlice'

const Profile = () => {
    const user = useSelector(selectUser)
    const loading = useSelector(selectLoading)

    const dispatch = useDispatch()

    if (!user) {
        return null
    }

    return (
        <div className='container-fluid mb-5 mt-4'>

            <h3>Profile</h3>
            <p>Update your personal information below</p>
            <p><a href={userDraftPreferencesUrl} target='_blank' className='btn btn-primary'>Tap here to update your draft preferences</a></p>

            <p>
                <b>
                    Reward Points Total: {user.reward_points_total}
                </b>
            </p>

            <div className="form-group">
                <label>Email</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='email'
                    value={user.email}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>Username</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='username'
                    value={user.username}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>Address</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='address'
                    value={user.address}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>First Name</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='first_name'
                    value={user.first_name}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>Last Name</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='last_name'
                    value={user.last_name}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>City</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='city'
                    value={user.city}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>State</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='state'
                    value={user.state}
                    className='form-control' />
            </div>

            <div className="form-group">
                <label>Zip</label>
                <input
                    onChange={(e) => dispatch(updateUserAttribute({name: e.target.name, value: e.target.value}))}
                    name='zip'
                    value={user.zip}
                    className='form-control' />
            </div>

            <button className='btn btn-success' onClick={() => dispatch(attemptUserUpdate())} disabled={loading}>Update Profile</button>
        </div>
    )
}

export default Profile
