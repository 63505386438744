import React from 'react'
import Message from './Message'

const ChatMessages = ({ messages, me, admin, messagesRef, token }) => {
    return (
        <div className="messages" ref={messagesRef}>
            {
                messages.map((message, idx) => {
                    return (
                        <Message
                            key={idx}
                            me={me}
                            {...message} />
                    )
                })
            }
        </div>
    )
}

export default ChatMessages