import React from 'react'
import Marquee, { Motion } from 'react-marquee-slider'
import { selectAnnouncement } from '../Breaks/breaksSlice';
import { useSelector } from 'react-redux'

const TextMarquee = ( {message} ) => {
    const announcement = useSelector(selectAnnouncement);
    
    return (
        <Marquee>
            {
                announcement.ticker_messages.map((message, index) => (
                    <div key={index} className='marquee-message'>
                        {message}
                    </div>
                ))
            }
        </Marquee>
    )
}

export default TextMarquee