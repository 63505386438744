import React from 'react'
import { selectVersion } from './appSettingsSlice'
import { useSelector } from 'react-redux';
import { setLocalVersion } from '../../lib/Storage'

const handleRefresh = (e, version) => {
    e.preventDefault()
    setLocalVersion(version)
    window.location.assign(`/?c=${Math.floor(Math.random() * 1000000000)}`)
}

const AppRefreshPrompt = () => {
    const version = useSelector(selectVersion)

    return (
        <div className='alert alert-warning' style={{ borderRadius: 0, textAlign: 'center' }}>
            <div>A newer version of the app was detected.</div>
            {/* eslint-disable-next-line */}
            <div><a href='#' onClick={(e) => handleRefresh(e, version)}>Press here to refresh the app</a></div>
        </div>
    )
}

export default AppRefreshPrompt
