import { createSlice } from '@reduxjs/toolkit'
import { appSettingsUrl } from '../../lib/Endpoints'
import Request from '../../lib/Request'
import { getLocalVersion, setLocalVersion } from '../../lib/Storage'

export const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState: {
        /**
         * The latest version of the app that comes back from he API
         */
        version: null,
        /**
         * The local version of the app which is stored in local storage
         */
        localVersion: getLocalVersion(),
        /**
         * Does the UI require refresh?
         */
        requiresRefresh: false
    },
    reducers: {
        setSettings: (state, action) => {
            state.version = action.payload.version
        },
        setRequiresRefresh: (state, action) => {
            state.requiresRefresh = action.payload
        }
    }
})

export const {
    setSettings,
    setRequiresRefresh
} = appSettingsSlice.actions

export const selectVersion = state => state.appSettings.version
export const selectRequiresRefresh = state => state.appSettings.requiresRefresh

export function loadSettings() {
    return async (dispatch, getState) => {
        const response = await Request.get(appSettingsUrl)

        // if the server doesn't have app settings data, just return
        if (!response.data) {
            console.log('No app settings data found from the API')
            return
        }

        console.log('Remote: ', response.data.version)
        console.log('Local: ', getState().appSettings.localVersion)

        const local = getState().appSettings.localVersion

        if (!local) {
            setLocalVersion(response.data.version)
        }

        // should the app refresh? when local is null we set it to false
        // just to be consistent
        const refresh = (local && response.data.version > local) || false

        console.log('App needs to be refreshed: ', refresh)

        dispatch(setRequiresRefresh(refresh))
        dispatch(setSettings(response.data))
    }
}

export default appSettingsSlice.reducer
