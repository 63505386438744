import React from 'react'
import { useEffect } from 'react'
import BreakCard from './BreakCard'
import { loadBreaks, selectSports, setSports } from './breaksSlice'
import { useDispatch, useSelector } from 'react-redux'
import { createConsumer } from '@rails/actioncable'
import { cableUrl } from '../../lib/Endpoints'

const BreaksOnSale = () => {
    const sports = useSelector(selectSports)

    const dispatch = useDispatch()

    useEffect(() => {
        if (sports.length) {
            return
        }

        /**
         * Load initial breaks from the server
         */
        dispatch(loadBreaks())

        /**
         * Connect our app to the remote websocket server for break/announcement updates
         */
        createConsumer(cableUrl).subscriptions.create('MobileBreaksChannel', {
            received({ data }) {
                dispatch(setSports(data))
            }
        })

    })

    let breaks = false;

    sports.forEach(s => {
        if (s.breaks.length) {
            breaks = true;
        }
    })


    console.log('Breaks: ', breaks)

    if (breaks) {
        return (
            <div id="breaks">
                {
                    sports.map(s => {
                        return s.breaks.map(b => {
                            return (
                                <BreakCard bk={b} key={b.id} />
                            )
                        })
                    })
                }
            </div>
        )
    } else {
        return (
            <div id="breaks" className='mt-4'>
                <h3>All spots are sold out right now, check back soon.</h3>
            </div>
        )
    }
}

export default BreaksOnSale
