import { createSlice } from "@reduxjs/toolkit";
import { leagueInterestUrl, updateLeagueInterestUrl } from "../../lib/Endpoints";
import Request from '../../lib/Request'

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState: {
        leagueInterest: []
    },
    reducers: {
        setLeagueInterest: (state, action) => {
            state.leagueInterest = action.payload
        },
        toggleSport: (state, action) => {
            state.leagueInterest = state.leagueInterest.map(sport => {
                if (action.payload.sportId === sport.sport.id) {
                    return {
                        ...sport,
                        enabled: action.payload.enabled
                    }
                } else {
                    return sport
                }
            })
        }
    }
})

export const {
    setLeagueInterest,
    toggleSport
} = alertsSlice.actions

export const selectLeagueInterest = state => state.alerts.leagueInterest

export function loadLeagueInterests() {
    return async (dispatch, getState) => {
        const response = await Request.get(leagueInterestUrl)
        dispatch(setLeagueInterest(response.data))
    }
}

export function updateLeagueInterests(sportId, enabled) {
    return async (dispatch, getState) => {
        // update the ui first
        dispatch(toggleSport({ sportId, enabled }))

        // our new league interests are really just all enabled sports, and sportId if enabled (argument wise), then mapped by the sport id
        const newPref = getState().alerts.leagueInterest.filter(sport => {
            if (sport.sport.id === sportId) {
                return enabled
            } else {
                return sport.enabled
            }
        }).map(sport => sport.sport.id)

        const response = await Request.post(updateLeagueInterestUrl, { league_interest: newPref })

        // set the league interest based on the server response
        // dispatch(setLeagueInterest(response.data.league_interest))
    }
}

export default alertsSlice.reducer
