import React from 'react'
import moment from 'moment'
import axios from 'axios'
import { actionChatMessageUrl } from '../../lib/Endpoints';

export default class Message extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            actionsOpen: false
        }
    }

    toggleActions = () => {
        this.setState({ actionsOpen: !this.state.actionsOpen });
    }

    delete = () => {
        if (!window.confirm('Are you sure you want to delete this message? This cannot be undone.')) {
            return;
        }

        axios.post(`${actionChatMessageUrl}/${this.props.message.id}.json`, {
            authenticity_token: null
        });
    }

    block = () => {
        if (!window.confirm('Are you sure you want to block this user?')) {
            return;
        }

        axios.post(`${actionChatMessageUrl}/block_user.json`, {
            authenticity_token: null,
            user_id: this.props.message.user_id
        });
    }

    mute = () => {
        if (!window.confirm('Are you sure you want to mute this user?')) {
            return;
        }

        axios.post(`${actionChatMessageUrl}/mute_user.json`, {
            authenticity_token: null,
            user_id: this.props.message.user_id
        }).then(response => {
            alert(response.data.message);
        });
    }

    render = () => {
        return (
            <div className={`message ${(this.props.me === this.props.message.user_id && this.props.message.user_id) || (this.props.admin === this.props.message.admin_id && this.props.message.admin_id) ? 'mine' : ''}`}>

                {
                    // show the actions toggle if the user is an admin and the message isn't an admin's message
                    // this.props.admin && this.props.message.user_id ? (
                    //     <div className="actions-toggle" onClick={this.toggleActions}>...</div>
                    // ) : null
                }

                <div className={`actions-dropdown ${this.state.actionsOpen ? 'open' : ''}`}>
                    <div className='action' onClick={this.delete}>Delete Message</div>
                    <div className='action' onClick={this.block}>Block User</div>
                    <div className='action' onClick={this.mute}>Mute User</div>
                </div>

                <div className="meta">
                    <div className="user">
                        {this.props.user.username}
                    </div>
                    <div className="time">
                        {moment(this.props.message.created_at).fromNow()}
                    </div>
                </div>

                <div className="text">{this.props.message.message}</div>
            </div>
        )
    }
}