import React from 'react'
import { selectAnnouncement } from '../Breaks/breaksSlice';
import { useSelector } from 'react-redux'
import Marquee from './Marquee';

const Annoucement = () => {
    const announcement = useSelector(selectAnnouncement);

    if (announcement) {
        console.log("IN ANNOUNCEMENT COMPONENT")
    }

    return (
        <div className="digital-display">
            <div className="digital-display-inner">
                <div className="digital-display-content">
                    <div className="marquee-container">
                        <Marquee />
                    </div>
                </div>
            </div>
        </div>
                
    )

}

export default Annoucement