import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/User/userSlice'
import breaksReducer from './features/Breaks/breaksSlice'
import appSettingsReducer from './features/AppSettings/appSettingsSlice'
import alertsReducer from './features/Alerts/alertsSlice'

export default configureStore({
    reducer: {
        user: userReducer,
        breaks: breaksReducer,
        appSettings: appSettingsReducer,
        alerts: alertsReducer
    }
})
