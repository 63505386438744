import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    attemptLogin,
    selectLoading
} from './userSlice'

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()

    const loading = useSelector(selectLoading)

    return (
        <div id="login">
            <div className="container-fluid">
                <div className="card bg-light mt-3">
                    <div className="card-body">
                        <div className="form-group">
                            <label>Email address</label>
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="email"
                                type="email"
                                className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                name="password"
                                type="password"
                                className="form-control" />
                        </div>
                        <button
                            type="submit"
                            className="btn btn-danger"
                            onClick={() => dispatch(attemptLogin(email, password))}
                            disabled={loading}>

                            Login

                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login