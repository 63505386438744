const tokenKey = 'ubb-mobile-token'
const localVersionKey = 'ubb-local-version'

export const setToken = token => {
    window.localStorage.setItem(tokenKey, token)
}

export const getToken = () => {
    return window.localStorage.getItem(tokenKey)
}

export const removeToken = () => {
    window.localStorage.removeItem(tokenKey)
}

export const setLocalVersion = version => {
    window.localStorage.setItem(localVersionKey, version)
}

export const getLocalVersion = () => {
    return window.localStorage.getItem(localVersionKey)
}
