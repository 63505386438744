import React from 'react'
import axios from 'axios'
import { chatMessagesUrl } from '../../lib/Endpoints'

export default class ChatInput extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            message: ''
        }
    }

    handleChange = (e) => {
        this.setState({ message: e.target.value });
    }

    evalEnterPress = (e) => {
        if (e.which === 13 && this.state.message.trim().length) {
            this.send();
        }
    }

    send = () => {
        const message = this.state.message.trim();

        this.setState({ message: '' });

        axios.post(`${chatMessagesUrl}`, {
            authenticity_token: null,
            chat_message: {
                user_id: this.props.me,
                admin_id: null,
                message: message
            }
        }).then(response => {
            if (!response.data.success) {
                alert(response.data.message);
            }

            if (response.data.blocked) {
                window.location.reload();
            }

            if (response.data.muted) {
                this.props.manuallyAppendMessage(response.data.message);
            }
        });
    }

    render = () => {
        return (
            <input
                onChange={this.handleChange}
                onKeyUp={this.evalEnterPress}
                value={this.state.message}
                type="text"
                className="chat-input"
                placeholder='Type message here... Press ENTER to send...' />
        )
    }
}