import React from 'react'
import { breakImageUrl, root } from '../../lib/Endpoints'

const BreakCard = ({ bk }) => {
    return (
        <div className="row">
            <div className="col">
                <div className="card mt-3 text-white bg-dark">
                    <div className="row no-gutters">
                        <div className="col-md-3">
                            <img src={breakImageUrl(bk.id)} className="card-img" alt="break" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body">
                                <h5 className="card-title">{bk.name}</h5>
                                <p>
                                    {/* eslint-disable-next-line */}
                                    <a href={`${root}?b=${bk.id}`} className="btn btn-danger font-weight-bold" target="_blank">
                                        ${Number.parseFloat(bk.cost_per_spot).toFixed(2)} / { bk.max_spots - bk.entries_sold } Spots Remaining
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreakCard
