import React from 'react'
import { useSelector } from 'react-redux'
import { selectAnnouncement } from './breaksSlice'
import UpcomingSportBreak from './UpcomingSportBreak'
import { upcomingSportImage } from '../../lib/Endpoints'

const UpcomingBreaks = () => {
    const announcement = useSelector(selectAnnouncement)

    return ['nfl', 'nba', 'mlb'].filter(s => announcement[s]).map(sport => {
        return (
            <UpcomingSportBreak
                key={sport}
                sport={sport}
                image={upcomingSportImage(sport)} />
        )
    })
}

export default UpcomingBreaks
