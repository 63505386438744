import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadLeagueInterests, selectLeagueInterest } from './alertsSlice'
import SportAlertToggle from './SportAlertToggle'

const Alerts = () => {

    const leagueInterest = useSelector(selectLeagueInterest)
    const dispatch = useDispatch()

    useEffect(() => {
        if (leagueInterest.length) {
            return
        }

        dispatch(loadLeagueInterests())
    })

    return (
        <div className="container-fluid mt-4">
            <h3>Alerts</h3>
            <p>Toggle the sports below to set your push notification preferences on a per-sport basis.</p>
            {
                leagueInterest.map((sport, index) => {
                    return <SportAlertToggle key={index} sport={sport} />
                })
            }
        </div>
    )
}

export default Alerts