import { createSlice } from "@reduxjs/toolkit"
import Request from '../../lib/Request'
import { loginUrl, loadUserByTokenUrl, updateUserUrl } from "../../lib/Endpoints"
import { setToken } from "../../lib/Storage"
import { evalToken } from '../../lib/Token'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        token: evalToken(),
        user: null,
        rewardPoints: null,
        loading: false
    },
    reducers: {
        logUserIn: (state, action) => {
            state.user = action.payload.user
            state.token = action.payload.user.auth_token
            state.rewardPoints = action.payload.reward_points
            state.loading = false

            setToken(action.payload.user.auth_token)
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        },
        updateUserAttribute: (state, action) => {
            state.user[action.payload.name] = action.payload.value
        },
        updateUser: (state, action) => {
            state.user = action.payload
            state.loading = false
        }
    }
})

export const {
    logUserIn,
    setLoading,
    updateUserAttribute,
    updateUser
} = userSlice.actions

export const selectToken = state => state.user.token
export const selectLoading = state => state.user.loading
export const selectUser = state => state.user.user

export function attemptLogin(email, password) {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        try {
            const response = await Request.post(loginUrl, { email, password })
            dispatch(logUserIn(response.data))
        } catch (e) {
            dispatch(setLoading(false))
        }
    }
}

export function loadUserByToken() {
    return async (dispatch, getState) => {
        const response = await Request.get(loadUserByTokenUrl)
        dispatch(logUserIn(response.data))
    }
}

export function attemptUserUpdate() {
    return async (dispatch, getState) => {
        dispatch(setLoading(true))

        const user = getState().user.user

        try {
            const response = await Request.patch(updateUserUrl(user.id), { user })
            dispatch(updateUser(response.data))
            window.alert('Your profile was updated.')
        } catch (e) {
            dispatch(setLoading(false))
        }

    }
}

export default userSlice.reducer
