import React from 'react'
import { root } from '../../lib/Endpoints'

const PersonalBreakCard = () => {
    return (
        <div className="card mt-3 text-white bg-dark">
            <div className="row no-gutters">
                <div className="col-md-3">
                    <img src={`${root}/images/ultimate-box-breaks-logo.png`} className="card-img" alt="break" style={{ padding: '10px' }} />
                </div>
                <div className="col-md-8">
                    <div className="card-body">
                        <h5 className="card-title">Personal Breaks Available</h5>
                        <p>
                            {/* eslint-disable-next-line */}
                            <a href={`${root}/personal_breaks`} className="btn btn-danger font-weight-bold" target="_blank">
                                Personal Breaks Available - View Here
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalBreakCard
