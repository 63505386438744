import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { removeToken } from '../../lib/Storage'
import { selectToken } from '../User/userSlice'
import { useSelector } from 'react-redux'

const logout = (e) => {
    e.preventDefault()
    removeToken()
    window.location.assign('/')
}

const Nav = () => {
    const token = useSelector(selectToken)

    const [open, setOpen] = useState(false);

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-danger">
            <img src="/logo.png" style={{maxWidth: '150px'}} alt='logo' />

            <button onClick={() => setOpen(true)} className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className={`navbar-collapse collapse ${open ? 'show' : ''}`} id="navbarTogglerDemo02">
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                    
                    {
                        token && (
                            <React.Fragment>
                                <li className="nav-item active">
                                    <Link onClick={() => setOpen(false)} to='/' className='nav-link'>Upcoming Breaks</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link onClick={() => setOpen(false)} to='/live' className='nav-link'>Watch Live Breaks</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link onClick={() => setOpen(false)} to='/profile' className='nav-link'>My Profile</Link>
                                </li>
                                <li className="nav-item active">
                                    <Link onClick={() => setOpen(false)} to='/alerts' className='nav-link'>Alerts</Link>
                                </li>
                                <li className="nav-item active">
                                    {/* eslint-disable-next-line */}
                                    <a className="nav-link" href="#" onClick={logout}>Log Out</a>
                                </li>
                            </React.Fragment>
                        )
                    }
                </ul>
            </div>
        </nav>
    )
}

export default Nav
