import React from 'react'
import Chat from '../Chat/Chat'
import { useSelector } from 'react-redux'
import { selectUser } from '../User/userSlice'

const Live = () => {
    const user = useSelector(selectUser);
    return (
        <div>
            <iframe 
                src="https://player.twitch.tv/?autoplay=false&channel=ultimateboxbreaks&parent=ultimateboxbreaks.com&parent=ui.ultimateboxbreaks.com" 
                frameborder="0" 
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen scrolling="auto"  
                height="340" 
                width="100%">
            </iframe>
            <Chat
                user={user?.id} />
        </div>
    )
}

export default Live
