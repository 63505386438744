import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Breaks from './features/Breaks/Breaks'
import Profile from './features/User/Profile'
import Alerts from './features/Alerts/Alerts'
import Live from './features/Live/Live'

const AppRouter = () => {
    return (
        <Switch>
            <Route path='/profile'>
                <Profile />
            </Route>
            <Route path='/live'>
                <Live />
            </Route>
            <Route path='/alerts'>
                <Alerts />
            </Route>
            <Route path='/'>
                <Breaks />
            </Route>
        </Switch>
    )
}

export default AppRouter
